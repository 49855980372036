/*    
<summary>
   This is the Client Component which shows the Clients Data.
   Developer: Aashish Singh, Created Date:04-March-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useState, useCallback, useEffect, ReactElement } from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { ColumnType } from "../../constants/enums/gridColumnTypeEnum";
import { useStore } from "../../contexts/StoreProvider";
import useWindowDimensions, {
  getTableMaxHeight,
} from "../../helpers/windowResize.helper";
import { ISorting } from "../../models/ISorting";
import Grid, { getColumns } from "../../shared-components/Grid/Grid";
import SearchField from "../../shared-components/SearchField/SearchField";
import { formatMessage } from "../../translations/formatMessage";
import Pagination from "../../shared-components/Grid/Pagination";
import IPageInfo from "../../models/IPageInfo";
import { IActionFormatter, IFormatter } from "../../models/IFormatter";
import Header from "../../shared-components/Header/Header";
import {
  PageLimitOptions,
  initialPageLimit,
} from "../../constants/pageLimit.constant";
import { Button, Container } from "react-bootstrap";
import DeletePopupHOC from "../../shared-components/Popup/Hoc/DeletePopupHoc";
import AddPopupHOC from "../../shared-components/Popup/Hoc/AddPopupHoc";
import EditPopupHOC from "../../shared-components/Popup/Hoc/EditPopupHoc";
import { Col, ListGroup, Row } from "react-bootstrap";
import AddUpdateClient from "./components/add-edit-client";
import { IClient, IClientList } from "../../models/response/IClientResponse";
import Footer from "../../shared-components/Footer/Footer";
import SubHeader from "../../shared-components/Header/SubHeader";
import FilterPopupHOC from "../../shared-components/Popup/Hoc/FilterPopupHoc";
import ClientFilterForm from "./forms/client-filter-form";
import { ModuleName } from "../../constants/moduleName.constant";
import SpinnerLoader from "../../shared-components/Loader/SpinnerLoader";
import "./client.scss";
import ConfirmPopupHOC from "../../shared-components/confirmationPopup/hoc/confirmation-popup-hoc";
import DeleteConfirmPopup from "../../shared-components/confirmationPopup/confirm-popup";
import NoData from "../../assets/Images/no_record.png";
import SlidingPanel from "react-sliding-side-panel";
import ClientDetails from "./components/client-details";
import _ from "lodash";
import { clientInitialFilterState } from "../../core/initialState/initial-filter-states";
import UrlConstants from "../../constants/url.constant";

interface IProps {
  tabKey: string;
  setTabKey: (k: string) => void;
  size: {
    height: number;
  };
  showDeletePopup: boolean;
  deleteToggleHandler: () => void;
  showAddPopup: boolean;
  addToggleHandler: () => void;
  showEditPopup: boolean;
  editToggleHandler: () => void;
  showFilterPopup: boolean;
  filterToggleHandler: () => void;
  showConfirmPopup: boolean;
  confirmToggleHandler: () => void;
}

const Client: React.FC<IProps> = (props: IProps) => {
  const { clientStore, preferencesStore } = useStore();
  const {
    tabKey,
    setTabKey,
    showDeletePopup,
    deleteToggleHandler,
    showAddPopup,
    addToggleHandler,
    showFilterPopup,
    filterToggleHandler,
    showConfirmPopup,
    confirmToggleHandler,
  } = props;
  const {
    inProgress,
    error,
    clientList,
    GetClientListService,
    getClientList,
    reset,
    resetError,
    DeleteClientService,
    DeleteClientState,
    resetDeleteClientState,
    addUpdateClientState,
    resetGetClientDetail,
    filter,
    setFilterDetail,
  } = clientStore;

  const [selectedId, setSelectedId] = useState<number>(-1);
  const { CSVFileDownloadPostService } = preferencesStore;
  const [selectedRow, setSelectedRow] = useState<IClientList | undefined>(
    undefined
  );
  const [openPanel, setOpenPanel] = useState(false);
  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [disablePagination, setDisablePagination] = useState<boolean>(false);
  const clientListData: Array<IClientList> = getClientList;
  const [pageLimit, setPageLimit] = useState<number>(initialPageLimit);
  const [searchText, setSearchText] = useState("");
  const [sorting, setSorting] = useState<ISorting>({
    column: "userName",
    orderBy: "asc",
  });

  // const [filterData, setFilterData] = useState<any>({...filter});

  const [isApiCalled, setIsApiCalled] = useState<boolean>(false);

  const [pageInfo, setPageInfo] = useState<IPageInfo>({
    currentPage: 1,
    totalPages: 1,
  });

  const { height, width } = useWindowDimensions();

  const onPageChanged = useCallback((pageNumber: number) => {
    setPageInfo((pageInfo) => ({ ...pageInfo, currentPage: pageNumber }));
  }, []);

  const onPageLimitChangedForUser = (
    evt: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setPageLimit(parseInt(evt.target.value));
  };

  const onSearchTextChanged = (text: string) => {
    setSearchText(text);
  };

  const exportFilteredLogsCSV = () => {
    let tempFilterDetails = { ..._.omit(filter, ["isFilterActive"]) };
    tempFilterDetails = {
      ...tempFilterDetails,
      IsActive:
        filter.IsActive == "2" ? null : filter.IsActive == "1" ? false : true,
    };
    CSVFileDownloadPostService(
      UrlConstants.ClientCSVDownload,
      "Accounts",
      tempFilterDetails
    );
  };

  /**
   * This function calls the "GetClientListService" store funtion that get client list from API
   */
  const callGetClientList = useCallback(() => {
    setDisablePagination(true);
    GetClientListService(
      pageInfo.currentPage,
      pageLimit,
      sorting.orderBy,
      sorting.column,
      _.omit(filter, ["isFilterActive"])
    );
    setIsApiCalled(true);
  }, [
    GetClientListService,
    pageInfo.currentPage,
    pageLimit,
    sorting.orderBy,
    sorting.column,
    filter,
  ]);

  // useEffect(() => {
  //   return()=>{
  //     resetFilterHandler();
  //   }
  // }, []);

  // useEffect(() => {
  //   callGetClientList();
  // }, [callGetClientList]);

  // useEffect(() => {
  //   if (isApiCalled) {
  //     // showUserDetailToggleHandler();
  //     setIsApiCalled(false);
  //   }
  // }, [isApiCalled]);
  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "filter" of the useEffect changed.
   */
  useEffect(() => {
    if (filter.isFilterActive) {
      callGetClientList();
    } else callGetClientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, callGetClientList]);

  useEffect(() => {
    setPageInfo((pageInfo) => ({
      ...pageInfo,
      totalPages: clientList?.TotalPages,
    }));
    setDisablePagination(false);
  }, [getClientList, clientList.TotalPages]);

  useEffect(() => {
    if (addUpdateClientState.success) {
      callGetClientList();
    }
  }, [addUpdateClientState.success, callGetClientList]);

  useEffect(() => {
    if (error) {
      toast.error(formatMessage(error));
      resetError();
    }
  }, [error, resetError]);

  useEffect(() => {
    if (DeleteClientState.success) {
      toast.success(formatMessage("deleted_success"));
      resetDeleteClientState();
      // deleteToggleHandler();
      confirmToggleHandler();
      callGetClientList();
    }
  }, [DeleteClientState.success, reset, callGetClientList]);

  useEffect(() => {
    if (DeleteClientState.error) {
      toast.error(formatMessage(DeleteClientState.error));
      resetDeleteClientState();
    }
  }, [DeleteClientState.error, resetDeleteClientState]);

  /**
   * This function shows the add client pop up from where we can add new client and update existing client. we use id to identify
   * that we are adding or updating client.
   * "Id == -1" : we are adding new client
   * "Id != -1" : means we are updating existing client
   */
  const openAddUpdateHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    addToggleHandler();
  };

  const openFilterHandler = () => {
    closePanelHandler();
    filterToggleHandler();
  };

  const closePanelHandler = () => {
    setSelectedRow(undefined);
    setOpenPanel(false);
    resetGetClientDetail();
  };

  /**
   * This function shows the delete client confirmation popup component and sets the seleted client Id
   */
  const deleteClickHandler = (row: { Id: number }) => {
    closePanelHandler();
    setSelectedId(row.Id);
    setChangeDetails(false);
    // deleteToggleHandler();
    confirmToggleHandler();
  };

  /**
   * This function deletes the client by providing Id to the Api
   */
  const deleteHandler = () => {
    DeleteClientService(selectedId);
  };

  /**
   * This function shows the detail client panel
   */
  const showDetailsClickHandler = (row: { Id: number }) => {
    setSelectedId(row.Id);
    setChangeDetails(true);
    // !showUserDetail && showUserDetailToggleHandler();
    setIsApiCalled(false);
  };

  const filterSubmitHandler = (values: any) => {
    closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...values, isFilterActive: true });
    props.filterToggleHandler();
  };

  const resetFilterHandler = () => {
    // closePanelHandler();
    setPageInfo({ ...pageInfo, currentPage: 1 });
    setFilterDetail({ ...clientInitialFilterState });
    // setFilterData({
    //   ...clientInitialFilterState
    // });
  };

  const rowEvents = (row: any) => {
    console.log(row);
    setSelectedRow(row);
    setOpenPanel(true);
  };

  const handleSortingChange = (accessor: string) => {
    let sortOrder = sorting.orderBy;
    if (accessor === sorting.column) {
      sortOrder = sorting.orderBy === "asc" ? "desc" : "asc";
    }
    setSorting({ column: accessor, orderBy: sortOrder });
  };

  const addIcons: Array<IActionFormatter<ReactElement>> = [
    {
      handler: openAddUpdateHandler,
      icon: (
        <svg height="14" viewBox="0 0 492.49284 492" width="14">
          <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0" />
          <path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0" />
        </svg>
      ),
      // key: "IsEditable",
      key: "",
      title: "label_edit",
      className: "btn-warning",
    },
    {
      handler: deleteClickHandler,
      icon: (
        <svg
          version="1.1"
          x="0px"
          y="0px"
          width="13"
          height="13"
          viewBox="0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M62.205,150l26.569,320.735C90.678,493.865,110.38,512,133.598,512h244.805c23.218,0,42.92-18.135,44.824-41.265
    L449.795,150H62.205z M180.986,452c-7.852,0-14.458-6.108-14.956-14.063l-15-242c-0.513-8.276,5.771-15.395,14.033-15.908
    c8.569-0.601,15.381,5.757,15.908,14.033l15,242C196.502,444.632,189.721,452,180.986,452z M271,437c0,8.291-6.709,15-15,15
    c-8.291,0-15-6.709-15-15V195c0-8.291,6.709-15,15-15s15,6.709,15,15V437z M360.97,195.938l-15,242
    c-0.493,7.874-7.056,14.436-15.908,14.033c-8.262-0.513-14.546-7.632-14.033-15.908l15-242
    c0.513-8.276,7.764-14.297,15.908-14.033C355.199,180.543,361.483,187.662,360.97,195.938z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M451,60h-90V45c0-24.814-20.186-45-45-45H196c-24.814,0-45,20.186-45,45v15H61c-16.569,0-30,13.431-30,30
    c0,16.567,13.431,30,30,30c137.966,0,252.039,0,390,0c16.569,0,30-13.433,30-30C481,73.431,467.569,60,451,60z M331,60H181V45
    c0-8.276,6.724-15,15-15h120c8.276,0,15,6.724,15,15V60z"
              />
            </g>
          </g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
          <g></g>
        </svg>
      ),
      // key: "IsDeletable",
      key: "",
      title: "label_delete",
    },
    {
      handler: rowEvents,
      icon: (
        <svg
          enableBackground="new 0 0 519.643 519.643"
          width="13"
          height="13"
          viewBox="0 0 519.643 519.643"
        >
          <circle cx="259.823" cy="259.866" r="80" />
          <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
        </svg>
      ),
      key: "",
      title: "label_showdetails",
      className: "btn-secondary",
    },
  ];

  const columns = getColumns<IClient, number>(
    [
      {
        columnType: ColumnType.Hidden,
        accessorKey: "Id",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "Name",
        sortingName: "userName",
        displayName: "client_name",
      },
      {
        columnType: ColumnType.ActionsFormatter,
        icons: addIcons,
        displayName: "label_action",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "IsActive",
        // sortingName: "IsActive",
        displayName: "status",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "EnableLimitSettings",
        displayName: "limit_label",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "EnableFuelSensorSettings",
        displayName: "fuel_sensor_label",
      },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "EnableNozzleRfidSetting",
        displayName: "nozzle_rfid_label",
      },
      // {
      //   columnType: ColumnType.TextHeader,
      //   accessorKey: "EnableDriverIdSettings",
      //   displayName: "driver_id_enabled",
      // },
      {
        columnType: ColumnType.TextHeader,
        accessorKey: "EnableGpsSettings",
        displayName: "gps_label",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "State",
        sortingName: "state",
        displayName: "state",
      },
      {
        columnType: ColumnType.Sorting,
        accessorKey: "Country",
        sortingName: "country",
        displayName: "country",
      },
      // {
      //   columnType: ColumnType.Sorting,
      //   accessorKey: "PhoneNo",
      //   sortingName: "PhoneNo",
      //   displayName: "phone_no",
      // },
      // {
      //   columnType: ColumnType.Sorting,
      //   accessorKey: "limitEnabled",
      //   sortingName: "limit_enabled",
      //   displayName: "limit_enabled",
      // },
      // {
      //   columnType: ColumnType.Sorting,
      //   accessorKey: "fuelSensorEnabled",
      //   sortingName: "fuel_sensor_enabled",
      //   displayName: "fuel_sensor_enabled",
      // },
      // {
      //   columnType: ColumnType.Sorting,
      //   accessorKey: "GPSEnabled",
      //   sortingName: "gps_enabled",
      //   displayName: "gps_enabled",
      // },
      // {
      //   columnType: ColumnType.Sorting,
      //   accessorKey: "DriverIdEnabled",
      //   sortingName: "driver_id_enabled",
      //   displayName: "driver_id_enabled",
      // },
      // {
      //   columnType: ColumnType.Sorting,
      //   accessorKey: "NozzleRFIDEnabled",
      //   sortingName: "nozzle_rfid_enabled",
      //   displayName: "nozzle_rfid_enabled",
      // },
      // {
      //   columnType: ColumnType.Sorting,
      //   accessorKey: "AlarmEnabled",
      //   sortingName: "alarm_enabled",
      //   displayName: "alarm_enabled",
      // },
    ],
    sorting,
    handleSortingChange
  );

  console.log("selected", selectedRow);

  return (
    <React.Fragment>
      {showAddPopup && (
        <AddUpdateClient id={selectedId} modalClosed={addToggleHandler} />
      )}
      {showFilterPopup && (
        <ClientFilterForm
          initialValues={_.omit(filter, ["isFilterActive"])}
          submitHandler={filterSubmitHandler}
          onModalClosed={filterToggleHandler}
          actionInProgress={false}
        />
      )}
      {showConfirmPopup && (
        <DeleteConfirmPopup
          title="delete_client"
          modalSubmit={deleteHandler}
          modalClosed={confirmToggleHandler}
          isLoading={DeleteClientState.inProgress}
          message="delete_client_confirm"
          dialogClassName="delete_popup"
        />
      )}
      {/* <div className={`main-content user ${showUserDetail ? 'table-container' : ''} `}> */}
      <div className={`main-content user`}>
        <SubHeader
          moduleNameString={"client_title"}
          moduleName={ModuleName.Clients}
          addHandler={openAddUpdateHandler}
          filterHandler={openFilterHandler}
          isFilterActive={filter.isFilterActive}
          exportCSVHandler={exportFilteredLogsCSV}
          resetFilterHandler={resetFilterHandler}
          isAdd={true}
          addButtonTitle="add_client"
          isFullView={false}
          isDownload={true}
          isRefresh={false}
          isFilter={true}
          isDateFilter={false}
          isImport={false}
          isMissing={false}
        />
      </div>

      {inProgress && <SpinnerLoader />}

      <Container fluid className="p-0">
        {clientListData.length !== 0 ? (
          <div className="clientWrapper background-image" id="moveContent">
            <div className={`${openPanel ? "paneIsOpen" : ""}`}>
              <div className="pt-3 px-3">
                <div className="rounded shadow">
                  <div className="Fuel-table">
                    <div
                      className="table-responsive"
                      style={getTableMaxHeight(height, width)}
                      data-testid="table"
                    >
                      {clientListData && (
                        <Grid
                          data={clientListData}
                          columns={columns}
                          isInProgress={inProgress}
                          hiddenColumns={["Id"]}
                        />
                      )}
                      {/* <small className="userLogin position-relative">
                   
                   <span className="status online"></span>
                 </small> */}
                    </div>
                  </div>

                  <Container fluid>
                    <Row className="tableFooter py-1">
                      <Col xs="3" className="d-flex">
                        <b className="text-white text-hidden-m mt-1 pt-1">
                          <FormattedMessage id="show" />:{" "}
                        </b>
                        <select
                          data-testid="pageSelect"
                          className="ms-2 from-control"
                          onChange={onPageLimitChangedForUser}
                          value={pageLimit}
                        >
                          {PageLimitOptions.map((op) => (
                            <option key={op} value={op}>
                              {op}
                            </option>
                          ))}
                        </select>
                      </Col>

                      <Col className="d-flex justify-content-end" xs="9">
                        <Pagination
                          totalPages={pageInfo.totalPages}
                          pageLimit={pageLimit}
                          onPageChanged={onPageChanged}
                          disablePagination={disablePagination}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
            <SlidingPanel
              type={"right"}
              isOpen={openPanel}
              noBackdrop={false}
              size={100}
            >
              <div>
                <div className="row g-0 border-bottom pt-3 pb-1 ps-3">
                  <Col lg={10} md={6} xs={6}>
                    <h2 className="fs-4 fw-bolder d-flex">
                      {" "}
                      {selectedRow ? selectedRow.Name : ""}
                      {selectedRow?.IsActive === "Active" ? (
                        <div className="activeClient">
                          <span className="badge badge-pill active ms-3 mb-0">
                            <FormattedMessage id="active" />
                          </span>
                        </div>
                      ) : (
                        <div className="inActiveClient">
                          <span className="badge badge-pill ms-3 inactive mb-0">
                            <FormattedMessage id="inactive" />
                          </span>
                        </div>
                      )}
                    </h2>
                  </Col>
                  <Col className="text-end" lg={2} md={6} xs={6}>
                    <svg
                      onClick={closePanelHandler}
                      role="button"
                      xlinkTitle="close"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                  </Col>
                </div>
                <div className="group_detailsWrapper">
                  <ClientDetails selectedData={selectedRow} />
                </div>
              </div>
            </SlidingPanel>
          </div>
        ) : (
          <div className="no_data_table background-image ">
            <div className="no_data w-100 text-capitalize text-center">
              <img src={NoData} alt="NOData" className="NOData" />
              <h1 className="fs-2">
                <FormattedMessage id="message_no_datafound" />
              </h1>
            </div>
          </div>
        )}
      </Container>

      <Footer />
    </React.Fragment>
  );
};

export default ConfirmPopupHOC(
  FilterPopupHOC(EditPopupHOC(AddPopupHOC(DeletePopupHOC(observer(Client)))))
);
